import React from 'react';
import {
    Container,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
    Modal,
    Box,
    Grid,
    ButtonGroup, Alert, Divider, Skeleton
} from '@mui/material';
import {Col} from "react-bootstrap";
import SignatureTemplate from "./SignatureTemplate";
import {useTranslation} from "react-i18next";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: '80%',
    outline: 'none',
};



const EmailSignature = () => {

    const [open, setOpen] = React.useState(false);
    const [lang, setLang] = React.useState<'en' | 'de'>('en');
    const [small, setSmall] = React.useState(false);
    const {t} = useTranslation()

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const copyWithStyle = ( element: string ) => {

        const doc = document;
        const text = doc.getElementById( element );
        let range;
        let selection;

        // @ts-ignore
        if( doc.body.createTextRange ) {
            // @ts-ignore
            range = doc.body.createTextRange();
            range.moveToElement( text );
            range.select();

        } else if ( window.getSelection ) {

            selection = window.getSelection();

            range = doc.createRange();
            // @ts-ignore
            range.selectNodeContents( text );
            // @ts-ignore
            selection.removeAllRanges();
            // @ts-ignore
            selection.addRange( range );

        }

        document.execCommand( 'copy' );
        // @ts-ignore
        window.getSelection().removeAllRanges();

    }

    const handleCopy = () => {
        copyWithStyle('email_description')
    };

    return (
        <div>
            <Container maxWidth={false}>
                <Col xs={12} md={7} lg={5} xl={4} className="mb-4">
                    <Card>
                        {/*<CardMedia*/}
                        {/*    component="img"*/}
                        {/*    height="140"*/}
                        {/*    image="/static/images/cards/contemplative-reptile.jpg"*/}
                        {/*    alt="green iguana"*/}
                        {/*/>*/}
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {t("Email signature")}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {t("Reminds customers to review your business every time you have an email conversation.")}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button color="primary" fullWidth variant="contained" onClick={handleOpen}>
                                {t("Install email signature")}
                            </Button>
                        </CardActions>
                    </Card>
                </Col>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <Grid container>
                            <Grid item xs={12} lg={4} p={3} xl={6}>
                                <Typography sx={{paddingTop: 2}} variant="h6">{t("Select Signature")}</Typography>
                                <ButtonGroup>
                                    <Button variant={!small ? "contained" : "outlined"} onClick={() => setSmall(false)}>{t("Wide")}</Button>
                                    <Button variant={small ? "contained" : "outlined"} onClick={() => setSmall(true)}>{t("Minimal")}</Button>
                                </ButtonGroup>
                                <Typography sx={{paddingTop: 2}} variant="h6">{t("Select Language")}</Typography>
                                <ButtonGroup sx={{paddingBottom: 2}}>
                                    <Button variant={lang === 'en' ? "contained" : "outlined"} onClick={() => setLang('en')}>{t("English")}</Button>
                                    <Button variant={lang === 'de' ? "contained" : "outlined"} onClick={() => setLang('de')}>{t("German")}</Button>
                                </ButtonGroup>
                                <Alert severity="info">
                                    {t("Copy the email signature and set it as the default email signature to automatically ask for reviews in every email conversation. Need more information? Read our help article")}
                                </Alert>
                            </Grid>
                            <Grid item xs={12} lg={8} xl={6} bgcolor={'#f9f9fb'} p={3}>
                                <Typography variant="h6">{t("Preview")}</Typography>
                                <Typography variant="body2" color={'#a8a8a8'}>{t("From: you")}</Typography>
                                <Typography variant="body2" color={'#a8a8a8'}>{t("To: your customer")}</Typography>
                                <Divider/>
                                <br/>
                                <Skeleton variant="text" animation={false}/>
                                <Skeleton variant="text" animation={false}/>
                                <Skeleton variant="text" width={200} animation={false}/>
                                <br/>
                                <Box minHeight={330}>
                                    <SignatureTemplate lang={lang} small={small}/>
                                </Box>
                                <br/>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <CardActions>
                            <Box display="flex" justifyContent="space-between" width={'100%'} p={2}>
                                <Button size="small" color="primary" onClick={handleClose}>
                                    {t("Cancel")}
                                </Button>
                                <Button color="primary" variant="contained" onClick={handleCopy}>
                                    {t("Copy Signature")}
                                </Button>
                            </Box>
                        </CardActions>
                    </Box>
                </Modal>
            </Container>
        </div>
    );
}

export default EmailSignature;
